<template>
  <div :class="[$style.container, $props.fixed && $style.fixed]">
    <img :class="$style.cura" src="./assets/cura.png" />
    <div :class="$style.marquee">
      <div :class="$style.line" v-for="i in 4" :key="i">
        <template v-for="j in 2" :key="j">
          <span :class="$style.text">Техработы</span>
          <img :class="$style.arrow" src="./assets/arrow-up.svg?url" />
          <span :class="$style.text">Техработы</span>
          <img :class="$style.arrow" src="./assets/arrow-down.svg?url" />
        </template>
      </div>
    </div>

    <div :class="$style.message">
      <span :class="$style.text">
        на сайте идут технические работы.
        <template v-if="endOfMaintenance > Date.now()">
          Мы вернемся через
          <ui-time
            format="future-time"
            :value="endOfMaintenance"
            clock
            :timeout="1000"
          />
        </template>
      </span>
    </div>
  </div>
</template>

<script setup>
import UiTime from '~/components/ui/time/index.vue'
import appConfig from '~/app.config'

const endOfMaintenance = appConfig.endOfMaintenance
const $props = defineProps({
  fixed: { type: Boolean, default: false }
})
</script>

<style lang="scss" module>
.container {
  background-image: url(@/components/splashscreen/maintenance/assets/bg.jpg);
  background-size: cover;
  background-position: center;
  background-color: #050c18;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50em 0 min(20em, 200px);
  min-height: 100vh;
  min-height: 100dvh;

  @include down(sm) {
    background-image: url(@/components/splashscreen/maintenance/assets/bg-mob.jpg);
    padding: 26em 0 min(10em, 100px);
  }
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.cura {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% - 14em)) rotate(-25deg);
  width: 50em;
  height: auto;
  display: block;
  z-index: 2;

  @include down(sm) {
    width: 28em;
  }
}

.message {
  text-align: center;
  max-width: 52em;
  margin: 10em auto 0;
  text-transform: uppercase;

  @include down(sm) {
    max-width: calc(100% - 3.2em);
    margin-top: 6em;
  }

  .text {
    font-family: var(--primary-font);
    font-size: 4em;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0;

    @include down(sm) {
      font-size: 3.2em;
    }
  }
}

.marquee {
  background: #ffff00;
  color: #0a0e29;
  transform: rotate(-5deg);
  height: 8em;
  display: flex;
  align-items: center;
  width: 104%;
  position: relative;
  left: -2%;

  @include down(sm) {
    height: 4.5em;
  }
}

.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  animation: move 16s linear infinite;

  .text {
    font-family: var(--primary-font);
    font-size: 4em;
    font-weight: 700;
    letter-spacing: 0;
    text-transform: uppercase;

    @include down(sm) {
      font-size: 2.2em;
    }
  }

  .arrow {
    margin: 0 3.5em;

    @include down(sm) {
      margin: 0 2.24em;
      height: 1em;
      width: auto;
    }
  }
}

@keyframes move {
  from {
    transform: translate(-100%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}
</style>
